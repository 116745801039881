import Styles from "stylesheets/index/PopupPointResult.module.scss";
import CommonStyles from "stylesheets/CommonStyles.module.scss";
import FontStyles from "stylesheets/FontStyles.module.scss";
import { formatComma } from "utiles/formatComma";
import clsx from "clsx";

interface Color {
  appAreaTxtColor: string;
}

export interface Props {
  coinStyle?: string;
  isSale: boolean;
  isSur: boolean;
  pointIconUrl: string;
  color: Color;
  basePoint: number;
  actualPoint: number;
  bonusPoint: number;
}

export const PopupPointResult = ({
  coinStyle,
  isSale,
  isSur,
  pointIconUrl,
  color,
  basePoint,
  actualPoint,
  bonusPoint,
}: Props) => {
  const surText: string = isSur && "MAX";
  const surPointCalc = (): JSX.Element => {
    const resultText: JSX.Element = isSale ? (
      <>
        <span className={clsx(Styles.before, FontStyles.bold)}>
          {`${surText} ${formatComma(basePoint)}`}
        </span>
        {pointIconUrl && <img className={coinStyle} src={pointIconUrl} alt="コイン" />}
        <span
          className={clsx(Styles.after, FontStyles.bold)}
          style={{ color: color.appAreaTxtColor, textAlign: isSale ? "end" : undefined }}
        >
          {`${surText}  ${formatComma(actualPoint)}`}
          <br />
          {bonusPoint > 0 && <>+{formatComma(bonusPoint)}</>}
        </span>
      </>
    ) : (
      <>
        {pointIconUrl && <img className={coinStyle} src={pointIconUrl} alt="コイン" />}
        <span style={{ color: color.appAreaTxtColor }}>
          {`${surText} ${formatComma(basePoint)}`}
          {bonusPoint > 0 && <>+{formatComma(bonusPoint)}</>}
        </span>
      </>
    );

    return resultText;
  };

  const pointCalc = (): JSX.Element => {
    const resultText: JSX.Element = isSale ? (
      <>
        <span className={clsx(Styles.before, FontStyles.bold)}>{formatComma(basePoint)}</span>
        {pointIconUrl && <img className={coinStyle} src={pointIconUrl} alt="コイン" />}
        <span
          className={clsx(Styles.after, FontStyles.bold)}
          style={{ color: color.appAreaTxtColor, textAlign: isSale ? "end" : undefined }}
        >
          {formatComma(actualPoint)}
          <br />
          {bonusPoint > 0 && <>+{formatComma(bonusPoint)}</>}
        </span>
      </>
    ) : (
      <>
        {pointIconUrl && <img className={coinStyle} src={pointIconUrl} alt="コイン" />}
        <span style={{ color: color.appAreaTxtColor }}>
          {formatComma(basePoint)}
          {bonusPoint > 0 && <>+{formatComma(bonusPoint)}</>}
        </span>
      </>
    );

    return resultText;
  };

  return (
    <div className={clsx(Styles.result, CommonStyles.center)} data-testid="result">
      {isSur ? surPointCalc() : pointCalc()}
    </div>
  );
};
