import { useEffect } from "react";
import ReactModal from "react-modal";
import { useDispatch } from "react-redux";
import closeIcon from "images/close_icon.png";
import Styles from "stylesheets/index/RecommendOffer.module.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "stylesheets/slick.scss";
import { Button } from "components/common/Button";
import DlButtonName from "components/common/dlButtonName";
import { PopupPointResult } from "components/index/PopupPointResult";
import { Link } from "react-router-dom";
import clsx from "clsx";
import PopupModules from "slices/popup";
import { calcTotalPoint } from "utiles/calcTotalPoint";
import { formatComma } from "utiles/formatComma";

interface Color {
  appAreaBgColor: string;
  appAreaTxtColor: string;
  cvConditionColor: string;
  dlBtnBgColor: string;
  dlBtnTxtColor: string;
}
interface Props {
  linkUrl: string;
  pointIconUrl: string;
  color: Color;
  isSale: boolean;
  imageUrl: string;
  name: string;
  AchievementPointName: string;
  isSur: boolean;
  basePoint: number;
  actualPoint: number;
  dlBtnName: string;
  isOpen: boolean;
  isTripMile: boolean;
  nextpopup: () => void;
  bonusPoint: number | null;
}

export const RecommendOffer = ({
  linkUrl,
  pointIconUrl,
  color,
  isSale,
  imageUrl,
  name,
  AchievementPointName,
  isSur,
  basePoint,
  actualPoint,
  dlBtnName,
  isOpen,
  isTripMile,
  nextpopup,
  bonusPoint,
}: Props) => {
  const dispatch = useDispatch();
  const recommendOfferText: string = isSale ? "今だけ増量中！" : "こちらがオススメ！";

  useEffect(() => {
    const touchMoveHandler = (event: { preventDefault: () => void }) => {
      event.preventDefault();
    };
    document.addEventListener("touchmove", touchMoveHandler, { passive: false });
    return () => {
      document.removeEventListener("touchmove", touchMoveHandler);
    };
  }, []);

  return (
    <>
      <ReactModal
        isOpen={isOpen}
        overlayClassName={Styles.overlay}
        shouldFocusAfterRender={false}
        className={Styles["offer-content"]}
        testId="popup"
        onAfterOpen={() => (document.body.style.overflow = "hidden")}
        onAfterClose={() => (document.body.style.overflow = "auto")}
        ariaHideApp={false}
      >
        <div className={Styles.wrapper}>
          <div className={Styles["offer-description"]}>{recommendOfferText}</div>
          <Link
            to={linkUrl}
            className={Styles.link}
            onClick={() => dispatch(PopupModules.actions.setIsPopup(true))}
          >
            {imageUrl && (
              <img
                src={imageUrl}
                alt="バナー"
                style={{ background: color.appAreaBgColor }}
                className={Styles.image}
              />
            )}
            <div
              className={Styles.descriptionWrapper}
              style={{ backgroundColor: color.appAreaBgColor }}
            >
              <div className={Styles.description}>
                <div>
                  <div className={Styles.title} style={{ color: color.appAreaTxtColor }}>
                    {name}
                  </div>
                  <div className={Styles.offerTileContainer}>
                    {AchievementPointName && (
                      <p
                        className={clsx(Styles.offerTileType, Styles.title)}
                        style={{ color: color.cvConditionColor }}
                      >
                        {AchievementPointName}
                      </p>
                    )}
                  </div>
                </div>
                <div className={Styles.pointdescription}>
                  <div className={Styles["point-title"]} style={{ color: color.appAreaTxtColor }}>
                    <PopupPointResult
                      coinStyle={Styles.icon}
                      isSale={isSale}
                      isSur={isSur}
                      pointIconUrl={pointIconUrl}
                      color={{
                        appAreaTxtColor: color.appAreaTxtColor,
                      }}
                      basePoint={basePoint}
                      actualPoint={actualPoint}
                      bonusPoint={bonusPoint}
                    />
                  </div>
                </div>
              </div>
              <div className="test">
                <Button
                  title={
                    <DlButtonName
                      pointIconUrl={pointIconUrl}
                      dlBtnName={dlBtnName}
                      totalPoint={<>{formatComma(calcTotalPoint(actualPoint, bonusPoint ?? 0))}</>}
                      bonusPoint={bonusPoint}
                    />
                  }
                  className={isTripMile ? Styles.detailButtonTripMile : Styles.detailButton}
                  color={{
                    bgColor: color.dlBtnBgColor,
                    txtColor: color.dlBtnTxtColor,
                  }}
                  isPortraitFixed={isTripMile}
                />
              </div>
            </div>
          </Link>
        </div>
        <div className={Styles["button-wrapper"]}>
          <img className={Styles["close-icon"]} src={closeIcon} onClick={() => nextpopup()} />
        </div>
      </ReactModal>
    </>
  );
};
