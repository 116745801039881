import { Expose } from "class-transformer";

export class Survey {
  id: number;

  @Expose({ name: "offerId" })
  offerId: number;

  @Expose({ name: "ansStatCd" })
  ansStatCd: string;

  @Expose({ name: "arrivalDay" })
  arrivalDay: string;

  @Expose({ name: "encryptId" })
  encryptId: string;

  @Expose({ name: "enqPerPanelStatus" })
  enqPerPanelStatus: string;

  @Expose({ name: "basePoint" })
  basePoint: number;

  @Expose({ name: "basePointMin" })
  basePointMin: number;

  @Expose({ name: "actualPoint" })
  actualPoint: number;

  @Expose({ name: "actualPointMin" })
  actualPointMin: number;

  @Expose({ name: "pointString" })
  pointString: string;

  @Expose({ name: "pointType" })
  pointType: number;

  @Expose({ name: "redirectSt" })
  redirectSt: string;

  @Expose({ name: "researchId" })
  researchId?: string;

  @Expose({ name: "situation" })
  situation: string;

  @Expose({ name: "status" })
  status: number;

  @Expose({ name: "title" })
  title: string;

  @Expose({ name: "pageComment" })
  pageComment: string;

  @Expose({ name: "maxQueNum" })
  maxQueNum: string;

  @Expose({ name: "company" })
  company: string;

  @Expose({ name: "iconUrl" })
  iconUrl: string;
}
