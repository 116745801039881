import { Expose, Type } from "class-transformer";
import { TimeSales, Bonus } from "./index";

export class ConversionPoints {
  name: string | null;

  point: number;

  @Expose({ name: "timeSale" })
  @Type(() => TimeSales)
  timeSale?: TimeSales;

  @Expose({ name: "bonus" })
  @Type(() => Bonus)
  bonus?: Bonus;
}
