import React from "react";
import { Link } from "react-router-dom";
import { selectRouter } from "store/selector";
import { useSelector } from "react-redux";
import { Offer } from "models";
import { OffersListItemBox, Props as OffersListItemProps } from "./OffersListItemBox";
import { OffersListItemIcon } from "./OffersListItemIcon";
import { OffersListItemSmallBox } from "./OffersListItemSmallBox";
import Styles from "SkyflagFrontendOfferwall/stylesheets/index/OffersList.module.scss";
import defaultPath from "utiles/path";
import { linkFromType } from "types/linkFromType";
import { DisplayTabType } from "utiles/offer";

export interface Props
  extends Omit<
    OffersListItemProps,
    | "iconUrl"
    | "isPreRegist"
    | "isRecommended"
    | "displayName"
    | "cvCondition"
    | "basePoint"
    | "isSur"
    | "timeSale"
    | "bonus"
    | "position"
    | "conversionPoints"
    | "actualPoint"
    | "isPercentage"
    | "isEasy"
    | "isBulk"
    | "cvEstimatedTime"
  > {
  offers: Offer[];
  linkFrom?: linkFromType;
}

export const OffersListAB = React.memo(({ offers, linkFrom, ...offersListItemProps }: Props) => {
  const { location } = useSelector(selectRouter);
  const queries = new URLSearchParams(window.location.search);

  return (
    <div className={Styles.offerList} id="offerList">
      {offers
        ?.filter((data) => data.id !== 0)
        .map((offerData: Offer, i: number) => {
          let OfferListItemComponent;

          switch (offersListItemProps.displayTabType) {
            case DisplayTabType.FeatureBox:
              OfferListItemComponent = OffersListItemBox;
              break;
            case DisplayTabType.FeatureIcon:
              OfferListItemComponent = OffersListItemIcon;
              break;
            default:
              OfferListItemComponent = OffersListItemSmallBox;
              break;
          }
          return (
            <Link
              to={{
                pathname: `${defaultPath.INDEX}/detail`,
                search: new URLSearchParams({
                  ...Object.fromEntries(queries.entries()),
                  offer: String(offerData.id),
                  position: String(i + 1),
                  linkfrom: String(linkFrom),
                }).toString(),
                state: { referrer: location },
              }}
              key={i}
            >
              <OfferListItemComponent
                position={i + 1}
                iconUrl={offerData.iconUrl}
                isRecommended={offerData.isRecommended}
                displayName={offerData.displayName}
                cvCondition={offerData.cvCondition}
                basePoint={offerData.basePoint}
                actualPoint={offerData.actualPoint}
                isSur={offerData.isSur}
                isPercentage={offerData.isPercentage}
                timeSale={{
                  startAt: offerData.conversionPoints.slice(-1)[0]?.timeSale?.startAt,
                  endAt: offerData.conversionPoints.slice(-1)[0]?.timeSale?.endAt,
                  point: offerData.conversionPoints.slice(-1)[0]?.timeSale?.point,
                }}
                {...offersListItemProps}
                isEasy={offerData.isEasy}
                isBulk={offerData.isBulk}
                cvEstimatedTime={offerData.cvEstimatedTime}
              />
            </Link>
          );
        })}
    </div>
  );
});
