import React, { useEffect } from "react";
import { useIsSale } from "SkyflagFrontendOfferwall/modules/useIsSale";
import { useLandscape } from "hooks/useLandscape";
import Styles from "SkyflagFrontendOfferwall/stylesheets/index/PointResult.module.scss";
import CommonStyles from "SkyflagFrontendOfferwall/stylesheets/CommonStyles.module.scss";
import FontStyles from "SkyflagFrontendOfferwall/stylesheets/FontStyles.module.scss";
import clsx from "clsx";
import { formatComma } from "utiles/formatComma";

interface TimeSale {
  startAt: Date;
  endAt: Date;
  point: number;
}

interface Bonus {
  startAt: Date;
  endAt: Date;
  point: number;
}

interface SuperSale {
  startAt: Date;
  endAt: Date;
  point: number;
}

export interface Props {
  timeSale: TimeSale;
  bonus: Bonus;
  superSale: SuperSale;
  pointIconUrl: string;
  pointUnit: string;
  basePoint: number;
  actualPoint: number;
  isSur: boolean;
  isPercentage: boolean;
}

export const PointResult = ({
  pointIconUrl,
  pointUnit,
  basePoint,
  superSale,
  timeSale,
  bonus,
  actualPoint,
  isSur,
  isPercentage,
}: Props) => {
  // SUR, セールをふまえたポイントの結果を表示する関数
  const surText: string = isSur ? "MAX " : "";
  const pointIcon: JSX.Element = (
    <>{pointIconUrl && <img src={pointIconUrl} alt="Pt" className={CommonStyles.pointIcon} />}</>
  );
  const [
    now,
    isInCurrencySale,
    confirmInCurrencySale,
    isInTimeSale,
    confirmInTimeSale,
    isInBonus,
    confirmInBonus,
    setDefault,
  ] = useIsSale();

  useEffect(() => {
    confirmInCurrencySale(superSale.startAt, superSale.endAt);
    confirmInTimeSale(timeSale.startAt, timeSale.endAt);
    confirmInBonus(bonus.startAt, bonus.endAt);
    return () => {
      setDefault();
    };
  }, [now]);

  const isLandscape = useLandscape();

  const surPointCalc = (): JSX.Element => {
    const resultText: JSX.Element =
      actualPoint !== basePoint ? (
        <>
          {isLandscape ? (
            <>
              <div className={Styles.LandscapeAfter}>
                {pointIcon}
                <span className={clsx(Styles.after, FontStyles.bold)}>
                  {`${surText}${formatComma(actualPoint)}`}
                  {isInBonus && <>+{formatComma(bonus.point)}</>}
                </span>
              </div>
              <div className={Styles.LandscapeBefore}>
                {pointIcon}
                <span className={clsx(Styles.before, FontStyles.tiny)}>
                  {`${surText}${formatComma(basePoint)}`}
                </span>
              </div>
            </>
          ) : (
            <>
              {pointIcon}
              <span className={clsx(Styles.before, FontStyles.tiny)}>
                {`${surText}${formatComma(basePoint)}`}
              </span>
              <span className={Styles.arrow}>▶︎</span>
              {pointIcon}
              <span className={clsx(Styles.after, FontStyles.bold)}>
                {`${surText}${formatComma(actualPoint)}`}
                {isInBonus && <>+{formatComma(bonus.point)}</>}
              </span>
            </>
          )}
        </>
      ) : (
        <>
          {pointIcon}
          <span className={FontStyles.bold}>
            {`${surText}${formatComma(basePoint)}`}
            {isPercentage ? (
              <>
                %分の{pointUnit}
                {isInBonus && (
                  <>
                    +{formatComma(bonus.point)}
                    {pointUnit}
                  </>
                )}
              </>
            ) : (
              <>
                {isInBonus ? (
                  <>
                    +{formatComma(bonus.point)}
                    {pointUnit}
                  </>
                ) : (
                  pointUnit
                )}
              </>
            )}
          </span>
        </>
      );

    return resultText;
  };

  const pointCalc = (): JSX.Element => {
    const resultText: JSX.Element =
      basePoint !== actualPoint ? (
        <>
          {isLandscape ? (
            <>
              <div className={Styles.LandscapeAfter}>
                {pointIcon}
                <span className={clsx(Styles.after, FontStyles.bold)}>
                  {formatComma(actualPoint)}
                  {isPercentage && "%"}
                  {isInBonus && <>+{formatComma(bonus.point)}</>}
                </span>
              </div>
              <div className={Styles.LandscapeBefore}>
                {pointIcon}
                <span className={clsx(Styles.before, FontStyles.tiny)}>
                  {formatComma(basePoint)}
                  {isPercentage && "%"}
                </span>
              </div>
            </>
          ) : (
            <>
              {pointIcon}
              <span className={clsx(Styles.before, FontStyles.tiny)}>
                {formatComma(basePoint)}
                {isPercentage && "%"}
              </span>
              <span className={Styles.arrow}>▶︎</span>
              {pointIcon}
              <span className={clsx(Styles.after, FontStyles.bold)}>
                {formatComma(actualPoint)}
                {isPercentage && "%"}
                {isInBonus && <>+{formatComma(bonus.point)}</>}
              </span>
            </>
          )}
        </>
      ) : (
        <>
          {pointIcon}
          <span className={FontStyles.bold}>
            {formatComma(basePoint)}
            {isPercentage ? (
              <>
                %分の{pointUnit}
                {isInBonus && (
                  <>
                    +{formatComma(bonus.point)}
                    {pointUnit}
                  </>
                )}
              </>
            ) : (
              <>
                {isInBonus ? (
                  <>
                    +{formatComma(bonus.point)}
                    {pointUnit}
                  </>
                ) : (
                  pointUnit
                )}
              </>
            )}
          </span>
        </>
      );
    return resultText;
  };

  return (
    <div
      className={clsx(
        Styles.result,
        (!isLandscape || (isLandscape && !(isInCurrencySale || isInTimeSale))) &&
          CommonStyles.center
      )}
      data-testid="result"
    >
      {isSur ? surPointCalc() : pointCalc()}
    </div>
  );
};
