import React, { useEffect } from "react";
import { useIsSale } from "modules/useIsSale";
import Styles from "stylesheets/index/PointResult.module.scss";
import CommonStyles from "stylesheets/CommonStyles.module.scss";
import FontStyles from "stylesheets/FontStyles.module.scss";
import { formatComma } from "utiles/formatComma";
import clsx from "clsx";

interface TimeSale {
  startAt: Date;
  endAt: Date;
}
interface Bonus {
  point: number;
  startAt: Date;
  endAt: Date;
}
interface SuperSale {
  startAt: Date;
  endAt: Date;
}

interface Color {
  appAreaTxtColor: string;
}

export interface Props {
  coinStyle?: string;
  timeSale: TimeSale;
  bonus: Bonus;
  superSale: SuperSale;
  pointIconUrl: string;
  color: Color;
  basePoint: number;
  actualPoint: number;
  isSur: boolean;
  isIndex?: boolean;
  isPercentage?: boolean;
}

export const PointResult = ({
  timeSale,
  bonus,
  superSale,
  pointIconUrl,
  color,
  basePoint,
  actualPoint,
  isSur,
  isIndex = false,
  coinStyle,
  isPercentage,
}: Props) => {
  // SUR, セールをふまえたポイントの結果を表示する関数
  // トップページはMAX表記がいらないのでトップページかどうかをpropsで持つようにしました 初期値はfalseです

  const surText: string = isSur && !isIndex ? "MAX" : "";

  const [now, , confirmInCurrencySale, , confirmInTimeSale, , confirmInBonus, setDefault] =
    useIsSale();

  useEffect(() => {
    confirmInCurrencySale(superSale.startAt, superSale.endAt);
    confirmInTimeSale(timeSale.startAt, timeSale.endAt);
    confirmInBonus(bonus.startAt, bonus.endAt);
    return () => {
      setDefault();
    };
  }, [now]);

  const surPointCalc = (): JSX.Element => {
    const resultText: JSX.Element =
      basePoint !== actualPoint ? (
        <>
          {!isIndex ? (
            <>
              <span className={clsx(Styles.before, FontStyles.bold)}>
                {`${surText} ${formatComma(basePoint)}`}
              </span>
              {pointIconUrl && (
                <img className={clsx(coinStyle, Styles.icon)} src={pointIconUrl} alt="コイン" />
              )}
              <span className={clsx(Styles.after, FontStyles.bold)}>
                {`${surText} ${formatComma(actualPoint)}`}
              </span>
            </>
          ) : (
            <>
              {pointIconUrl && (
                <img className={clsx(coinStyle, Styles.icon)} src={pointIconUrl} alt="コイン" />
              )}
              <span>{`${surText} ${formatComma(actualPoint)}`}</span>
            </>
          )}
        </>
      ) : (
        <>
          {!isIndex ? (
            <>
              {pointIconUrl && <img className={coinStyle} src={pointIconUrl} alt="コイン" />}
              <div>
                <span className={clsx(FontStyles.bold, FontStyles.mediumLarge)}>
                  {`${surText} ${formatComma(basePoint)}`}
                </span>
              </div>
            </>
          ) : (
            <>
              {pointIconUrl && <img className={coinStyle} src={pointIconUrl} alt="コイン" />}
              <span>{`${surText} ${formatComma(basePoint)}`}</span>
            </>
          )}
        </>
      );

    return resultText;
  };

  const pointCalc = (): JSX.Element => {
    const resultText: JSX.Element =
      basePoint !== actualPoint ? (
        <>
          {!isIndex ? (
            <>
              <span className={clsx(Styles.before, FontStyles.bold)}>{formatComma(basePoint)}</span>
              {pointIconUrl && (
                <img className={clsx(coinStyle, Styles.icon)} src={pointIconUrl} alt="コイン" />
              )}
              <span className={clsx(Styles.after, FontStyles.bold)}>
                {formatComma(actualPoint)}
              </span>
            </>
          ) : (
            <>
              {pointIconUrl && <img className={coinStyle} src={pointIconUrl} alt="コイン" />}
              <span>{formatComma(actualPoint)}</span>
            </>
          )}
        </>
      ) : (
        <>
          {!isIndex ? (
            <>
              {pointIconUrl && <img className={coinStyle} src={pointIconUrl} alt="コイン" />}
              <span className={clsx(FontStyles.bold, FontStyles.mediumLarge)}>
                {formatComma(basePoint)}
              </span>
            </>
          ) : (
            <>
              {pointIconUrl && <img className={coinStyle} src={pointIconUrl} alt="コイン" />}
              <span>{formatComma(basePoint)}</span>
            </>
          )}
        </>
      );
    return resultText;
  };

  return (
    <div
      className={clsx(Styles.result, CommonStyles.center)}
      data-testid="result"
      style={{ color: color.appAreaTxtColor }}
    >
      {isSur ? surPointCalc() : pointCalc()}
      {isPercentage && "%"}
    </div>
  );
};
