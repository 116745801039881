export class ScheduledReceivePoints {
  name: string | null;

  step: number;

  point: number;

  basePoint: number;

  actualPoint: number;

  isReceived: boolean;

  bonusPoint: number;
}
