import { Survey } from "models";

/**
 * 各オファーのアンケート使用状況を追跡するオブジェクト（abTestSurveyUsageTracker）を
 * クロージャとして保持する getNextSurveyForOffer 関数を生成します。
 * これにより、呼び出しごとに独立したトラッキング状態が維持されます。
 */
export const createGetNextSurveyForOffer = () => {
  const abTestSurveyUsageTracker: { [key: number]: number } = {};

  return (offerId: number, surveys: Survey[]) => {
    const currentIndex = abTestSurveyUsageTracker[offerId] ?? 0;
    const nextSurvey = surveys.filter((item) => item.offerId === offerId)[currentIndex];

    if (nextSurvey) {
      abTestSurveyUsageTracker[offerId] = currentIndex + 1;
    }
    return nextSurvey;
  };
};
