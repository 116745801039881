import React, { useEffect } from "react";
import { useIsSale } from "modules/useIsSale";
import stylesResponsive from "stylesheets/shared/ClockBanner.module.scss";
import stylesPortrait from "stylesheets/shared/ClockBannerPortrait.module.scss";
import { selectOfferwallState } from "store/selector";
import { useSelector } from "react-redux";
// import countBanner from "images/countbanner.png";
import LineBanner from "images/surper_sale_banner_line.png";
import LineBanner2 from "images/surper_sale_banner2_line.png";
import countBanner50 from "images/supersale_banner50_app.png";
// import countBannerWeb50 from "images/supersale_banner50_web.png";
import pickupBanner from "images/newcountbanner.png";
import toripMileBanner from "images/trip_mile_salebanner2.png";
// まだ使うようなのでコメントアウト。消すときはピッコマ担当に確認
// import countBanner100 from "images/supersale_banner100_app.png";
// import countBannerWeb100 from "images/supersale_banner100_web.png";
// import countBanner50 from "images/supersale_banner50_app.png";
import countBannerWeb50 from "images/supersale_banner50_web.png";
import countBanner100AnotherCv from "images/supersale_banner100_another_cv_app.png";
import { offerType, offerwallType } from "types/offerwallType";

// 倍率表示一旦コメントアウト(スーパーセールをわけるバックエンドの実装がされてから)
interface Props {
  start: Date;
  end: Date;
  pointMultiplier: number;
  offerwallType: offerwallType;
}

export const ClockBanner: React.FC<Props> = (props) => {
  // セール時間外ではnullを返すコンポーネントになる
  const { start, end, pointMultiplier, offerwallType } = props;
  const [
    now,
    isInCurrencySale,
    ,
    isInTimeSale,
    confirmInTimeSale,
    isInBonus,
    confirmInBonus,
    setDefault,
  ] = useIsSale();
  const { superSaleBannerImage } = useSelector(selectOfferwallState);
  const Styles = offerwallType === offerType.TRIP_MILE ? stylesPortrait : stylesResponsive;

  useEffect(() => {
    // 現在が更新される度にセール中かどうかを確認する
    confirmInTimeSale(start, end);
    confirmInBonus(start, end);
    return () => {
      // コンポーネントアンマウント時の処理
      setDefault();
    };
  }, [now]);

  if (!isInCurrencySale && !isInTimeSale && !isInBonus) return null; // セール中でなければ非表示に

  // 残り時間表示
  const saleEnd: Date = new Date(end);
  const diff: number = saleEnd.getTime() - now.getTime();
  const sec = Math.floor(diff / 1000) % 60; // ミリ秒を秒に直す
  const min = Math.floor(diff / 1000 / 60) % 60;
  const hour = Math.floor(diff / 1000 / 60 / 60);

  const digits = (num: number) => `00${num}`.slice(-2);
  const digitsHour = (num: number) => `000${num}`.slice(-3);

  return (
    <>
      <div className={Styles.root}>
        <div className={Styles.timer}>
          <p
            className={
              offerwallType === offerType.PICCOMA_APP || offerwallType === offerType.PICCOMA_WEB
                ? Styles.hour
                : Styles.hour_piccomaTemplate
            }
          >
            {digitsHour(hour)}
          </p>
          <p
            className={
              offerwallType === offerType.PICCOMA_APP || offerwallType === offerType.PICCOMA_WEB
                ? Styles.min
                : Styles.min_piccomaTemplate
            }
          >
            {digits(min)}
          </p>
          <p
            className={
              offerwallType === offerType.PICCOMA_APP || offerwallType === offerType.PICCOMA_WEB
                ? Styles.sec
                : Styles.sec_piccomaTemplate
            }
          >
            {digits(sec)}
          </p>
        </div>
        {offerwallType === offerType.PICKUP && (
          <div className={Styles.multiplier}>{pointMultiplier}</div>
        )}
        <p className={Styles.image}>
          {offerwallType === offerType.LINE_MANGA ? (
            <img src={pointMultiplier <= 1.5 ? LineBanner : LineBanner2} alt="" />
          ) : offerwallType === offerType.PICKUP ? (
            <img src={superSaleBannerImage ?? pickupBanner} alt="" />
          ) : offerwallType === offerType.TRIP_MILE ? (
            <img src={toripMileBanner} alt="" />
          ) : (
            <img
              src={
                offerwallType === offerType.PICCOMA_WEB
                  ? countBannerWeb50
                  : offerwallType === offerType.PICCOMA_APP
                  ? countBanner50
                  : countBanner100AnotherCv
              }
              alt=""
            />
          )}
        </p>
      </div>
    </>
  );
};
