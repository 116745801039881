import { Expose } from "class-transformer";

export class Bonus {
  @Expose({ name: "point" })
  point: number;

  @Expose({ name: "startAt" })
  startAt: Date;

  @Expose({ name: "endAt" })
  endAt: Date;
}
