import React from "react";
import CommonStyles from "stylesheets/CommonStyles.module.scss";
import { formatComma } from "utiles/formatComma";

export interface Props {
  pointIconUrl: string;
  dlBtnName: string;
  totalPoint?: JSX.Element;
  surText?: string;
  isPercentage?: boolean;
  nextConversionPointName?: string;
  bonusPoint?: number;
}

// 事前登録・Web案件・App案件の種類によってボタンの表示を出し分ける
const DlButtonName = ({
  pointIconUrl,
  dlBtnName,
  totalPoint,
  surText,
  isPercentage,
  nextConversionPointName,
  bonusPoint,
}: Props) => {
  // ボタンの表示に{pointIconUrl}, {point}が入っているときは必要なJSX.Elementを挿入する
  const convertBtnName = (string: string) => {
    const elements = string?.split(/\s*{|}{|}\s*/);
    const jsxElements = elements?.map((element, index) => {
      if (element === "pt_icon") {
        return (
          pointIconUrl && (
            <img src={pointIconUrl} className={CommonStyles.pointIcon} alt="ポイントアイコン" />
          )
        );
      }
      if (element === "point") {
        return (
          <>
            {surText} {totalPoint}
            {isPercentage && <>%{bonusPoint && bonusPoint > 0 && `+${formatComma(bonusPoint)}`}</>}
          </>
        );
      }
      if (element === "conversion") {
        return <>{nextConversionPointName}</>;
      }
      return <>{index === 0 ? element : ` ${element}`}</>;
    });
    const result = jsxElements?.reduce((prev, current) => (
      <>
        {prev}
        {current}
      </>
    ));
    return result;
  };

  const content = convertBtnName(dlBtnName);

  return <>{content}</>;
};
export default DlButtonName;
